<template>
  <!-- 异常包裹 -->
  <div class="container">
    <!-- 按钮列表 -->
    <div class="btnList1 wtl_btnList" ref="btnList">
      <div class="btnList1 wtl_btnList1">
        <el-button
          v-if="hasPermiss('ycgl_yiChangBaoGuo:add')"
          class="itemBtn btnColor"
          @click="NewException"
          >新建异常
        </el-button>
        <el-button
          v-if="hasPermiss('ycgl_yiChangBaoGuo:del')"
          class="itemBtn btnColor"
          @click="DeleteException"
          >删除异常
        </el-button>
        <el-button class="itemBtn btnColor" @click="DeleteException"
          >作废
        </el-button>
        <el-button
          v-if="hasPermiss('ycgl_yiChangBaoGuo:update')"
          class="itemBtn btnColor"
          @click="modifybut"
          >修改</el-button
        >
        <el-button
          v-if="hasPermiss('ycgl_yiChangBaoGuo:handel')"
          class="itemBtn btnColor"
          @click="exceptionHandling"
          >异常处理
        </el-button>
        <el-button class="itemBtn btnColor" @click="myModel"
          >表格设置</el-button
        >
        <el-dropdown @command="handleCommand_dc">
          <el-button class="dropdownBtn">
            导出<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="dc">导出</el-dropdown-item>
            <el-dropdown-item command="dcmb">下载导出模板</el-dropdown-item>
            <el-dropdown-item command="scmb">保存(上传)模板</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div
        class="singleinpbtn wtl_queryBoxNew"
        v-if="hasPermiss('ycgl_yiChangBaoGuo:query')"
      >
        <el-button class="seniorbut" @click="queryBtn_ok" icon="el-icon-search"
          >查询
        </el-button>
        <el-tooltip content="查询项恢复初始状态" placement="top">
          <div class="condition" @click="queryBtn_cz">清除</div>
        </el-tooltip>
        <el-tooltip content="查询更多条件" placement="top">
          <div class="condition" @click="more">
            {{ MoreConditions }}
          </div>
        </el-tooltip>
      </div>
    </div>
    <!-- 查询条件 -->
    <el-form :model="queryData">
      <div
        ref="queryCriteria"
        class="HeadInputbox"
        :class="{ HeadInputbox1: judge, active: isActive }"
      >
        <div class="item_right">
          <multitime
            :timeType="timeType"
            :timeTypes="timeTypes"
            :startTime="startTime_a"
            :endTime="endTime_a"
            @timestatus="timestatus"
            @queryBtn_ok="queryBtn_time"
          >
          </multitime>

          <div class="singleinp">
            <div class="singleinpleft">快递单号:</div>
            <div class="singleinpright">
              <el-input
                class="input"
                clearable
                v-model="number1"
                placeholder="请输入内容"
                @keyup.enter.native="queryBtn_ok"
              >
              </el-input>
            </div>
          </div>
          <div class="singleinp">
            <div class="singleinpleft">会员号:</div>
            <div class="singleinpright">
              <el-input
                class="input"
                v-model="queryData.memberId"
                placeholder="请输入"
                clearable
                @keyup.enter.native="queryBtn_ok"
              >
              </el-input>
            </div>
          </div>
          <div class="singleinp">
            <div class="singleinpleft">会员名称:</div>
            <div class="singleinpright">
              <el-input
                class="input"
                v-model="queryData.memberName"
                placeholder="请输入"
                clearable
                @keyup.enter.native="queryBtn_ok"
              >
              </el-input>
            </div>
          </div>
          <div class="singleinp">
            <div class="singleinpleft">快递公司:</div>
            <div class="singleinpright">
              <el-select
                class="queryInput"
                v-model="queryData.expressName"
                filterable
                clearable
                placeholder="请选择"
                @change="queryBtn_ok"
              >
                <el-option
                  v-for="item in expressArr"
                  :key="item.expressId"
                  :label="item.expressName"
                  :value="item.expressName"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="singleinp">
            <div class="singleinpleft">仓库:</div>
            <div class="singleinpright">
              <el-select
                class="queryInput"
                v-model="queryData.storageId"
                filterable
                clearable
                placeholder="请选择"
                @change="queryBtn_ok"
              >
                <el-option
                  v-for="item in storageArr"
                  :key="item.id"
                  :label="item.storageName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="singleinp">
            <div class="singleinpleft">包裹状态:</div>
            <div class="singleinpright">
              <el-select
                class="queryInput"
                v-model="queryData.packageStatus"
                filterable
                clearable
                placeholder="请选择"
                @change="queryBtn_ok"
              >
                <el-option
                  v-for="item in packageTypeArr"
                  :key="item.statusVal"
                  :label="item.showName"
                  :value="item.statusVal"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="singleinp">
            <div class="singleinpleft">单据类型:</div>
            <div class="singleinpright">
              <el-select
                class="queryInput"
                v-model="queryData.documentStatus"
                filterable
                clearable
                placeholder="请选择"
                @change="queryBtn_ok"
              >
                <el-option
                  v-for="item in documentTypeArr"
                  :key="item.id"
                  :label="item.showName"
                  :value="item.statusVal"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="singleinp">
            <div class="singleinpleft">异常类型:</div>
            <div class="singleinpright">
              <el-select
                class="queryInput"
                v-model="queryData.exceptionTypeName"
                filterable
                clearable
                placeholder="请选择"
                @change="queryBtn_ok"
              >
                <el-option
                  v-for="item in abnormalTypeArr"
                  :key="item.id"
                  :label="item.exceptionName"
                  :value="item.exceptionName"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="singleinp">
            <div class="singleinpleft">处理状态:</div>
            <div class="singleinpright">
              <el-select
                class="queryInput"
                v-model="queryData.isHandle"
                filterable
                clearable
                placeholder="请选择"
                @change="queryBtn_ok"
              >
                <el-option label="未处理" :value="0"></el-option>
                <el-option label="已处理" :value="1"></el-option>
              </el-select>
            </div>
          </div>
          <div class="singleinp">
            <div class="singleinpleft">处理类型:</div>
            <div class="singleinpright">
              <el-select
                class="queryInput"
                v-model="queryData.rejectionStatus"
                filterable
                clearable
                placeholder="请选择"
                @change="queryBtn_ok"
              >
                <el-option
                  v-for="item in dealType"
                  :key="item.exceptionHandleTypeId"
                  :label="item.handleName"
                  :value="item.exceptionHandleTypeId"
                >
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
        <!-- <div class="item_left" v-if="hasPermiss('ycgl_yiChangBaoGuo:query')">
          <div class="singleinpbtn" v-if="hasPermiss('ycgl_yiChangBaoGuo:query')">
            <el-button
              class="seniorbut"
              @click="queryBtn_ok"
              icon="el-icon-search"
              >查询
            </el-button>
            <el-tooltip content="查询项恢复初始状态" placement="top">
              <div class="condition" @click="queryBtn_cz">清除</div>
            </el-tooltip>
            <el-tooltip content="查询更多条件" placement="top">
              <div class="condition" @click="more">
                {{ MoreConditions }}
              </div>
            </el-tooltip>
          </div>
        </div> -->
      </div>
    </el-form>
    <!-- 表格 -->
    <el-table
      :height="tableHeigth"
      ref="mytable"
      :header-cell-style="{ background: '#f0f0f0' }"
      :data="tableData"
      stripe
      size="small"
      border
      show-summary
      :summary-method="getSummaries"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        fixed="left"
        label="序号"
        align="center"
        type="index"
        width="55"
      ></el-table-column>
      <el-table-column
        fixed="left"
        type="selection"
        align="center"
        width="55"
      ></el-table-column>
      <template v-for="(item, index) in myTableHeard">
        <el-table-column
          :key="index"
          :label="item.name"
          sortable
          :prop="item.field"
          :show-overflow-tooltip="true"
          align="center"
          :min-width="item.width"
          :data-isTotal="item.isTotal"
          v-if="item && item.isShow"
        >
        </el-table-column>
      </template>
    </el-table>
    <!-- 分页组件 -->
    <paging
      :pageNum="pageStart"
      :total="pageCount"
      :sizeList="sizeList"
      :size="pageTotal"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    >
    </paging>
    <sorttable
      :show_sortTableHeard="show_sortTableHeard"
      :allCheck="allCheck"
      :sortName="sortName"
      :tabelHeadeTitle="tabelHeadeTitle"
      :originalTabelHeadeTitle="originalTabelHeadeTitle"
      :selectLength="selectLength"
      @cancelSortHeard="cancelSortHeard"
      @confirmOk="confirmOk"
      @checktab="checktab"
      @checkAll="checkAll"
      @closeDias="closeDias"
    ></sorttable>

    <el-dialog
      title="上传编辑好的导出模板"
      :visible.sync="show_upload"
      append-to-body
      width="40%"
      center
    >
      <el-upload
        :action="uploadUrl"
        :show-file-list="true"
        :file-list="fileList"
        :limit="1"
        :http-request="uploadSectionFile"
      >
        <el-button size="small" type="primary">点击上传</el-button>
        <div slot="tip" class="el-upload__tip">上传模板</div>
      </el-upload>
      <div slot="footer" class="dialog-footer">
        <el-button @click="show_upload = false">关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { apiList, Api } from "@/assets/js/api";
import sorttable from "@/components/sortTemplate.vue";
import tools from "@/assets/js/tool";
import draggable from "vuedraggable"; //拖拽组件
import paging from "@/components/pagings.vue";
import multitime from "@/components/multiTime.vue";

export default {
  inject: ["reload"],
  components: {
    draggable,
    sorttable,
    paging,
    multitime,
  },
  data() {
    return {
      number1: "",
      isActive: true, //  输入框绑定动态class
      judge: false, //  输入框绑定动态class
      MoreConditions: "更多条件", //  输入框绑定动态class
      queryData: {},
      expressArr: [], //  快递公司下拉
      storageArr: [], //  仓库下拉
      packageTypeArr: [], //  包裹状态
      abnormalTypeArr: [], //  异常类型
      documentTypeArr: [], //  单据类型
      dealType: [], //  处理类型下拉
      pageStart: 1, //  分页配置
      pageCount: 0,
      pageTotal: 50,
      sizeList: [10, 20, 50, 100, 200],
      tableData: [], //  表格数据
      tableHeigth: "50vh", //  表格高度
      tabelHeadeTitle: [
        {
          name: "异常单号",
          field: "number",
          width: "140",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "异常类型",
          field: "exceptionTypeName",
          width: "140",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "来源单号",
          field: "documentNumber",
          width: "160",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "单据类型",
          field: "documentStatus",
          width: "100",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "备注",
          field: "comment",
          width: "160",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "异常状态",
          field: "isHandleShow",
          width: "90",
          sort: 11,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "处理网点",
          field: "storageName",
          width: "100",
          sort: 2,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "会员号",
          field: "userMemberNumber",
          width: "100",
          sort: 2,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "会员简称",
          field: "userMemberName",
          width: "100",
          sort: 2,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "会员标记",
          field: "userMemberTab",
          width: "100",
          sort: 2,
          isShow: true,
          isTotal: false,
          isSort: true,
        },

        {
          name: "处理类型",
          field: "handleTypeName",
          width: "100",
          sort: 2,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "处理人",
          field: "handlerUserName",
          width: "100",
          sort: 2,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "处理时间",
          field: "handleTime",
          width: "100",
          sort: 2,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "是否需要赔款",
          field: "isIndemnityShow",
          width: "110",
          sort: 2,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "赔款金额",
          field: "compensationAmount",
          width: "100",
          sort: 2,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "是否需要退货",
          field: "isReturnGoodsShow",
          width: "110",
          sort: 2,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "退货物流单号",
          field: "returnOrderNo",
          width: "110",
          sort: 2,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "退货快递费用",
          field: "expressAmount",
          width: "110",
          sort: 2,
          isShow: true,
          isTotal: false,
          isSort: true,
        },

        {
          name: "新建时间",
          field: "billPackageExceptionCreateTime",
          width: "160",
          sort: 2,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "新建人",
          field: "operatorUserName",
          width: "100",
          sort: 2,
          isShow: true,
          isTotal: false,
          isSort: true,
        },

        // =====================
      ], //  默认表头
      myTableHeard: [], //  动态表头
      totalArr: [], //  需要合计的字段
      singleData: [],

      //      end
      show_sortTableHeard: false, //显示弹窗
      show_upload: false, //显示上传弹窗
      originalTabelHeadeTitle: [],
      selectLength: 0, //显示的长度
      allCheck: false, //全选
      className: "", //导出需要
      fieldName: [], //下载字段名
      head: [], //下载表头字段
      fileList: [],
      uploadUrl: "", //上传路径
      sortName: "模板名", //排序名
      pageGroupName: "Abnormalpackage", //页面标识

      timeTypes: [
        //  时间类型
        {
          value: "0",
          label: "申请日期",
        },
      ],
      timeType: "0", //  时间类型
      times: [], //  开始结束时间
      startTime_a: "",
      endTime_a: "",
    };
  },
  updated() {
    if (this.show_sortTableHeard) {
      // 排序表头
      let arr = this.tabelHeadeTitle;
      for (var i = 0; i < arr.length; i++) {
        arr[i].sort = i + 1;
      }
      this.tabelHeadeTitle = arr;
    }
  },
  created() {
    if (this.originalTabelHeadeTitle.length == 0) {
      this.originalTabelHeadeTitle = JSON.parse(
        JSON.stringify(this.tabelHeadeTitle)
      );
    }

    this.getGroupFindFieldSort(); //获取用户自定义表头
    this.getStatusValList("package_v1.package_status", 1); //  包裹状态下拉
    this.getStatusValList("bill_package_exception_v1.document_status", 2); //  单据类型
    this.getAbnormalList(); //  异常类型
    this.getDealTypeList(); //  处理类型
    this.getCangkuList(); //  仓库下拉
    this.getExpressList(); //  快递公司下拉

    let endtime = tools.getCurrentDate(); //今天的时间;
    let sarttime = tools.getFlexDate(-10); //10天前
    this.startTime_a = sarttime;
    this.endTime_a = endtime;
  },
  mounted() {
    this.getData();
    // window.onresize = () => {
    //   return (() => {
    //     this.fetTableHeight();
    //   })();
    // };
  },
  watch: {},
  methods: {
    timestatus(type, s, e) {
      this.timeType = type || "0";
      this.startTime_a = s || "";
      this.endTime_a = e || "";

      if (type == "0") {
        this.timelist = [];
        this.timelistdata = [];
        this.getData();
      }
    },
    queryBtn_time(s, e) {
      this.startTime_a = s || "";
      this.endTime_a = e || "";
      this.getData();
    },

    getData() {
      // 获取数据
      this.queryData.pageStart = this.pageStart;
      this.queryData.pageTotal = this.pageTotal;
      Api.exceptionPackageList(this.queryData).then((res) => {
        if (res.data.status === "success") {
          this.className = res.data.result.className || "";
          this.tableData = res.data.result.data || [];
          this.pageCount = res.data.result.pageCount || 0;
          this.fetTableHeight();
        } else {
          this.$message.error(`${res.data.message}--获取数据`);
        }
      });
    },
    getStatusValList(s, t) {
      //  公共状态下拉
      Api.getStatusValList({
        tableAndFieldName: s,
      }).then((res) => {
        if (res.data.status === "success") {
          switch (t) {
            case 1:
              // 包裹状态
              this.packageTypeArr = res.data.result;
              break;
            case 2:
              // 单据类型
              this.documentTypeArr = res.data.result;
              break;
          }
        } else {
          console.log(`${res.data.message}--公共状态下拉`);
        }
      });
    },
    getCangkuList() {
      //  仓库下拉
      Api.getStorageList().then((res) => {
        if (res.data.status === "success") {
          this.storageArr = res.data.result;
        } else {
          console.log(`${res.data.message}--仓库下拉`);
        }
      });
    },
    getExpressList() {
      //  快递公司
      Api.expressChooseList().then((res) => {
        if (res.data.status === "success") {
          this.expressArr = res.data.result;
        } else {
          console.log(`${res.data.message}--快递公司`);
        }
      });
    },
    getAbnormalList() {
      //  异常类型
      Api.abnormalSList().then((res) => {
        if (res.data.status === "success") {
          this.abnormalTypeArr = res.data.result;
        } else {
          console.log(`${res.data.message}--异常类型`);
        }
      });
    },
    getDealTypeList() {
      //  异常处理类型
      Api.abnormalDealSList().then((res) => {
        if (res.data.status === "success") {
          this.dealType = res.data.result;
        } else {
          console.log(`${res.data.message}--异常处理类型`);
        }
      });
    },
    more() {
      //  输入框绑定动态class
      this.isActive = !this.isActive;
      this.judge = !this.judge;
      this.fetTableHeight();
      if (this.judge) {
        this.MoreConditions = "收起条件";
      } else {
        this.MoreConditions = "更多条件";
      }
    },

    queryBtn_ok() {
      // 查询按钮
      let nidlist = new Array();
      nidlist = tools.getOrderNum(this.number1);
      this.queryData.courierNumber = nidlist;
      this.pageStart = 1;
      this.getData();
    },

    queryBtn_cz() {
      // 重置按钮
      this.number1 = "";
      this.queryData = {};
    },

    handleSizeChange(val) {
      //  分页
      this.pageTotal = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.pageStart = val;
      this.getData();
    },

    NewException() {
      //  新建异常
      this.$router.push({
        path: "/exceptionhandling/Newexception",
      });
    },
    //导出操作
    handleCommand_dc(command) {
      if (command == "dc") {
        // 导出
        this.exportBtn();
      } else if (command == "dcmb") {
        // 下载模板
        this.exportTemplateDownload();
      } else if (command == "scmb") {
        // 上传模板
        this.upLoadBtn();
      }
    },

    //修改
    modifybut() {
      if (this.singleData.length === 1) {
        this.$router.push({
          path: "/exceptionhandling/Newexception",
          query: {
            id: this.singleData[0].exceptionId,
          },
        });
      } else {
        this.$message.warning("请选择一条数据");
      }
    },
    //删除异常
    DeleteException() {
      if (this.singleData.length > 0) {
        this.$confirm("是否删除异常?" + this.singleData.length + "条", "提示", {
          confirmButtonText: "是",
          cancelButtonText: "否",
          type: "warning",
        }).then(() => {
          let nidlist = new Array();
          this.singleData.forEach((item) => {
            nidlist.push(item.exceptionId);
          });
          let param = {
            packageExceptionId: nidlist,
          };
          param.sign = tools.getSign(param);
          Api.deletePackageException(param).then((res) => {
            if (res.data.status === "success") {
              this.$message.success("删除成功");
              this.getData();
            } else {
              console.log(`${res.data.message}--删除异常`);
            }
          });
        });
      } else {
        this.$message.warning("请选择一条数据");
      }
    },
    //异常处理
    exceptionHandling() {
      if (this.singleData.length === 1) {
        if (this.singleData[0].isHandle == 1) {
          this.$message.warning("当前异常单已处理,不能进行处理操作了");
          return false;
        }

        this.$router.push({
          path: "/exceptionhandling/Newexception",
          query: {
            editId: this.singleData[0].exceptionId,
            documentNumber: this.singleData[0].documentNumber,
          },
        });
      } else {
        this.$message.warning("请选择一条数据");
      }
    },
    // ===============================================导出部分=开始======
    // 上传导出模板
    uploadSectionFile(param) {
      ////console.log(param);
      const file = param.file,
        fileName = file.name;

      // 根据后台需求数据格式
      const form = new FormData();
      // 文件对象
      form.append("file", file);
      // 本例子主要要在请求时添加特定属性，所以要用自己方法覆盖默认的action
      form.append("fileName", fileName);
      let signArr = [
        {
          key: "fileName",
          val: fileName,
        },
      ];
      let sign = tools.getMD5Sign(signArr);
      form.append("sign", sign);
      // 项目封装的请求方法，下面做简单介绍
      Api.uploadFile(form)
        .then((res) => {
          if (res.data.status == "success") {
            let fileName = res.data.result.fileName;

            let signArr = [
              {
                key: "groupName",
                val: this.pageGroupName,
              },
              {
                key: "templateName",
                val: this.sortName,
              },
              {
                key: "fileName",
                val: fileName,
              },
            ];
            let sign_ = tools.getMD5Sign(signArr);
            Api.saveExportTemplate({
              groupName: this.pageGroupName,
              templateName: this.sortName,
              fileName: fileName,
              sign: sign_,
            }).then((res) => {
              this.$message.success(res.data.message || "更新成功");
              this.show_upload = false;
            });
          }
        })
        .catch((err) => {});
    },

    // 下载用户导出模板
    exportTemplateDownload() {
      var fieldName = this.fieldName;
      var head = this.head;
      if (head.length == 0) {
        let fieldNames = [];
        let heads = [];
        for (var i = 0; i < this.myTableHeard.length; i++) {
          if (this.myTableHeard[i].isShow) {
            fieldNames.push(this.myTableHeard[i].field);
            heads.push(this.myTableHeard[i].name);
          }
        }
        fieldName = fieldNames;
        head = heads;
      }
      //加密数组格式
      let signArr = [
        {
          key: "fieldName",
          val: fieldName,
        },
        {
          key: "head",
          val: head,
        },
      ];
      let sign = tools.getMD5Sign(signArr);
      let param = {
        fieldName: fieldName,
        head: head,
        sign: sign,
      };
      Api.exportTemplateDownload(param).then((res) => {
        let downloadurl = res.data.result.fileUrl || "";
        //下载(这里其实是直接访问)
        var a = document.createElement("a");
        a.setAttribute("href", downloadurl);
        a.setAttribute("target", "_blank");
        a.click();
      });
    },
    // 真正导出
    templateDataExport(customizedExportId) {
      let that = this;
      let datas = that.tableData;
      let parm = {
        customizedExportId: customizedExportId, //模板id
        className: that.className,
        data: datas,
      };
      let signarr = tools.getSignArr(parm);
      let signs_e = tools.getMD5Sign(signarr);
      Api.templateDataExport({
        customizedExportId: customizedExportId,
        className: that.className,
        data: JSON.stringify(datas),
        sign: signs_e,
      }).then((res) => {
        //下载(这里其实是直接访问)
        let downloadurl = res.data.result.fileUrl;
        var a = document.createElement("a");
        a.setAttribute("href", downloadurl);
        a.setAttribute("target", "_blank");
        a.click();
      });
    },
    // 上传按钮
    upLoadBtn() {
      this.show_upload = true;
    },
    // 点击导出按钮
    exportBtn() {
      let that = this;
      let fieldName = [];
      let head = [];
      for (var i = 0; i < this.myTableHeard.length; i++) {
        if (this.myTableHeard[i].isShow) {
          fieldName.push(this.myTableHeard[i].field);
          head.push(this.myTableHeard[i].name);
        }
      }
      this.fieldName = fieldName;
      this.head = head;

      // 1,先获取用户导出模板
      Api.getUserExportTemplate({
        groupName: this.pageGroupName,
      }).then((res) => {
        if (res.data.result && res.data.result[0].customizedExportId) {
          // 有自定义模板

          let customizedExportId = res.data.result[0].customizedExportId;
          that.templateDataExport(customizedExportId);
        } else {
          tools.dynamicExport(
            this.myTableHeard,
            this.className,
            this.tableData,
            "异常包裹列表"
          );
        }
      });
    },
    // ===============================================导出部分=结束======

    /**
     * ******************************计算表格高度**开始*************************
     */

    /**
     * ******************************计算表格高度**结束*************************
     */

    // 刷新页面==>
    reload_s() {
      this.reload(); //App.Vue里面的方法
    },

    // 提取快递单输入框的内容(无规则输入,定义了 换行,',' ,'空格三种')
    getStr(orderNos) {
      let arr = [];
      let arr_1 = [];
      if (orderNos.indexOf("\n") !== -1) {
        arr = orderNos.split("\n");
        //  ['123 344,dsg ewe','234 233','32,234']
        for (var i = 0; i < arr.length; i++) {
          let arr_a = [];
          if (arr[i].indexOf(",") !== -1) {
            arr_a = arr[i].split(",");
            arr_a.map((item) => {
              arr_1.push(item);
            });
          } else {
            if (arr[i]) {
              arr_1.push(arr[i]);
            }
          }
        }
        setTimeout(() => {
          for (var j = 0; j < arr_1.length; j++) {
            let arr_b = [];
            if (arr_1[j].indexOf(" ") !== -1) {
              arr_b = arr_1[j].split(" ");
              arr_b.map((item) => {
                arr_b.push(item);
              });
            } else {
              if (arr_1[j]) {
                arr_b.push(arr_1[j]);
              }
            }
          }
        }, 20);
      } else if (orderNos.indexOf(",") !== -1) {
        arr = orderNos.split(",");
        //  ['123 344','234','32 234']
        for (var b = 0; b < arr.length; b++) {
          let arr_a = [];
          if (arr[b].indexOf(" ") !== -1) {
            arr_a = arr[b].split(" ");
            arr_a.map((item) => {
              arr_1.push(item);
            });
          } else {
            if (arr[b]) {
              arr_1.push(arr[b]);
            }
          }
        }
      } else if (orderNos.indexOf(" ") !== -1) {
        arr = orderNos.split(" ");
        arr.map((item) => {
          if (item) {
            arr_1.push(item);
          }
        });
      } else {
        arr_1 = [orderNos];
      }
      let endArr = [];
      for (var q = 0; q < arr_1.length; q++) {
        if (arr_1[q].indexOf(" ") != -1) {
          let arrs = arr_1[q].split(" ");
          for (var w = 0; w < arrs.length; w++) {
            if (arrs[w]) {
              endArr.push(arrs[w]);
            }
          }
        } else {
          if (arr_1[q]) {
            endArr.push(arr_1[q]);
          }
        }
      }
      return endArr;
    },

    // 获取自定义的排序,没有就用默认的
    getGroupFindFieldSort() {
      let that = this;
      Api.getGroupFindFieldSort({
        groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
      }).then((res) => {
        let arr = res.data.result || [];
        if (arr.length > 0) {
          if (arr[0].sortObject) {
            that.myTableHeard = JSON.parse(arr[0].sortObject);
            this.sortName = arr[0].sortName;
          }
        } else {
          that.myTableHeard = that.tabelHeadeTitle;
        }
        let totalArr = [];
        //提取需要合计的字段
        for (var i = 0; i < that.myTableHeard.length; i++) {
          if (that.myTableHeard[i].isTotal) {
            totalArr.push(that.myTableHeard[i].field);
          }
        }
        that.totalArr = totalArr;
      });

      that.$nextTick(() => {
        that.$refs.mytable.doLayout();
      });
    },
    // 设置模板按钮
    myModel() {
      let that = this;
      that.show_sortTableHeard = true;
      that.tabelHeadeTitle = JSON.parse(JSON.stringify(that.myTableHeard));
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength = selectLength;
      if (selectLength == that.tabelHeadeTitle.length) {
        that.allCheck = true;
      } else {
        that.allCheck = false;
      }
    },
    // 表格选择事件
    handleSelectionChange(e) {
      this.singleData = e;
    },
    // 排序==>取消按钮
    cancelSortHeard() {
      this.show_sortTableHeard = false;
      this.$nextTick(() => {
        this.$refs.mytable.doLayout();
      });
    },
    // 排序==>确认按钮
    confirmOk(e) {
      this.tabelHeadeTitle = e;
      this.myTableHeard = this.tabelHeadeTitle;
      let sortObject = this.myTableHeard;
      let signs = [
        {
          key: "sortName",
          val: this.sortName, //模板名称
        },
        {
          key: "groupName",
          val: this.pageGroupName, //页面标识
        },
        {
          key: "sortObject",
          val: JSON.stringify(sortObject),
        },
      ];

      let sign = tools.getMD5Sign(signs);

      let param = {
        // apiPath: apiList.api_packageList, //综合查询接口路径
        groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
        sortName: this.sortName, //综合查询接口路径
        sortObject: JSON.stringify(sortObject),
        sign: sign,
      };
      Api.pushFindFieldSort(param).then((res) => {
        this.$message.success(res.data.message || "操作成功");
        this.getGroupFindFieldSort();
      });
      this.show_sortTableHeard = false; //关闭弹窗
      this.$nextTick(() => {
        this.$refs.mytable.doLayout();
      });
      this.show_sortTableHeard = false;
    },
    // 点击某一项
    checktab(idx, e) {
      let that = this;
      that.tabelHeadeTitle[idx].isShow = e;
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength = selectLength;
      if (selectLength == that.tabelHeadeTitle.length) {
        that.allCheck = true;
      } else {
        that.allCheck = false;
      }
    },
    //全选
    checkAll(e) {
      let that = this;
      this.allCheck = e;
      for (var j = 0; j < this.tabelHeadeTitle.length; j++) {
        this.tabelHeadeTitle[j].isShow = e;
      }
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength = selectLength;
    },
    //关闭弹窗
    closeDias(e) {
      this.show_sortTableHeard = e;
    },
    // 合计
    getSummaries(param) {
      let that = this;
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        for (var i = 0; i < that.totalArr.length; i++) {
          if (column.property == that.totalArr[i]) {
            if (!values.every((value) => isNaN(value))) {
              sums[index] = values.reduce((prev, curr) => {
                const value = Number(curr);
                if (!isNaN(value)) {
                  return tools.mathToFixed(tools.add(prev, curr), 2);
                } else {
                  return prev;
                }
              }, 0);
              sums[index] += "";
            } else {
              sums[index] = "---";
            }
          }
        }
      });
      this.$nextTick(() => {
        this.$refs.mytable.doLayout();
      });
      return sums;
    },

    // 上一页
    handlePrevPage() {
      this.pageNum = this.pageNum - 1 || 1;
      this.getData();
    },
    // 下一页
    handleNextPage() {
      this.pageNum = this.pageNum + 1 || 1;
      this.getData();
    },

    //    ---------  设置高度  ----------------

    resetHeight() {
      // 重置table高度
      return new Promise((resolve, reject) => {
        this.tableHeigth = 0;
        resolve();
      });
    },

    fetTableHeight() {
      // 设置table高度
      this.resetHeight().then((res) => {
        this.tableHeigth = this.getHeight();
        this.$nextTick(() => {
          this.$refs.mytable.doLayout();
        });
      });
    },

    getHeight() {
      // 获取表格高度
      let windonHeight = document.body.clientHeight;
      let queryHeigth;
      let btnListHeight = this.$refs.btnList.offsetHeight + 10; // 按钮列表  16==上下的margin
      if (this.judge) {
        queryHeigth = this.$refs.queryCriteria.offsetHeight; //查询框
      } else {
        queryHeigth = 50;
      }
      let pagsHeigth = 40; //分页组件
      var otherHeight = queryHeigth + btnListHeight + pagsHeigth + 60; //60是顶部导航 ,10==预留空位
      return Math.floor(windonHeight - otherHeight) || "50vh"; //
    },
  },
};
</script>

<style scoped lang="scss">
</style>
